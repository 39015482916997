import React, { useState, useEffect } from "react";
import { Person, Group, Description, AddCard, SettingsAccessibility } from "@mui/icons-material";
import {
  BUSCAR_INFO_INMUEBLE,
  BUSCAR_SERVICIOS_INMUEBLE,
  BUSCAR_INFO_CONTRATO
} from "../../utils/apiUrls";
import { convertirFecha } from "../../utils/funciones";
import AgregarPropiedad from "./AgregarPropiedad";
const PropertyCard = ({
  propiedad,
  onEditPropietario,
  onEditInquilino,
  onEditContrato,
  onEditServicios
}) => {
  const [data, setData] = useState(null);
  const [contrato, setContrato] = useState(null);
  const [openEditPropiedad, setOpenEditPropiedad] = useState(false);
  const [actualiza, setActualiza] = useState(false)
  const onEdit = () => {
    setOpenEditPropiedad(true);
  };

  useEffect(
    () => {
      const fetchData = async () => {
        try {
          const response = await fetch(BUSCAR_INFO_INMUEBLE, {
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify({ id_inmueble: propiedad.id })
          });
          if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || "Error en la solicitud");
          }
          const data = await response.json();
          setData(data);
          //pedimos servicios
          const response2 = await fetch(BUSCAR_SERVICIOS_INMUEBLE, {
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify({ id_inmueble: propiedad.id })
          });
          if (!response2.ok) {
            const errorData = await response2.json();
            throw new Error(errorData.message || "Error en la solicitud");
          }
          const data2 = await response.json();
          setData(data2);
        } catch (error) {
          console.error("Error de solicitud:", error.message);
        }
        //pedimos contratos
        const response3 = await fetch(BUSCAR_INFO_CONTRATO, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ id_inmueble: propiedad.id })
        });
        if (!response3.ok) {
          setContrato(null);
        } else {
          const data3 = await response3.json();
          setContrato(data3[0]);
        }
      };

      fetchData();
    },
    [propiedad.id]
  );

  return (
    <div
      style={{ maxHeight: "calc(100vh - 247px)" }}
      className="max-h-full bg-white shadow-lg rounded-xl overflow-hidden my-6 border border-gray-300 transition-shadow hover:shadow-xl duration-300"
    >
      {!openEditPropiedad
        ? <div className="px-8 py-4 h-full">
            <div className="flex">
              <div className="flex mx-auto">
                <h2 className="text-3xl font-bold mb-6 text-blue-600 ">{`${propiedad.direccion} ${propiedad.altura}, ${propiedad.piso} - ${propiedad.unidad}`}</h2>
                <div className="h-[30px] -mr-4 ml-2">
                  <button
                    className="bg-blue-500 hover:bg-blue-600 text-white py-2  mb-4 px-4  rounded-full text-sm shadow-md transition-transform transform hover:scale-105"
                    onClick={onEdit}
                  >
                    Editar
                  </button>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-10 -mt-2">
              {/* Inquilino */}
              {/* Contrato */}
              <div className="border-r-2 pr-6">
                <SectionHeader
                  title="Contrato"
                  icon={<Description className="text-yellow-600" />}
                  onEdit={() => onEditContrato(propiedad)}
                />
                <div className="-mt-2">
                  {contrato
                    ? <DetailsList
                        items={[
                          {
                            label: "Estado",
                            value: contrato ? "Activo" : "Inactivo"
                          },
                          {
                            label: "Fecha de Inicio",
                            value: contrato
                              ? convertirFecha(contrato.fecha_inicio)
                              : "Cargando..."
                          },
                          {
                            label: "Duración",
                            value: contrato
                              ? `${contrato.duracion_contrato} Años`
                              : "Cargando..."
                          },
                          {
                            label: "Tipo de Aumento",
                            value: contrato
                              ? contrato.indice_aumento
                              : "Cargando..."
                          },
                          {
                            label: "Intervalo de Aumento",
                            value: contrato
                              ? `${contrato.tipo_aumento} Meses`
                              : "Cargando..."
                          }
                        ]}
                      />
                    : <NoAssignmentMessage />}
                </div>
              </div>
              <div>
                <SectionHeader
                  title="Inquilino"
                  icon={<Group className="text-green-600" />}
                  onEdit={() => onEditInquilino(propiedad)}
                />
                <div className="-mt-2">
                  {data
                    ? <DetailsList
                        items={[
                          {
                            label: "Nombre",
                            value: data
                              ? `${data[0].nombre} ${data[0].apellido}`
                              : "Cargando..."
                          },
                          {
                            label: "Dirección",
                            value: data ? data[0].direccion : "Cargando..."
                          },
                          {
                            label: "Teléfono",
                            value: data ? data[0].telefono : "Cargando..."
                          },
                          {
                            label: "Email",
                            value: data ? data[0].email : "Cargando..."
                          },
                          {
                            label: "DNI",
                            value: data ? data[0].dni : "Cargando..."
                          }
                        ]}
                      />
                    : <NoAssignmentMessage />}
                </div>
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-10 mt-6">
              {/* Propietario */}
              <div className="border-r-2 pr-6">
                <SectionHeader
                  title="Propietario"
                  icon={<Person className="text-blue-600" />}
                  onEdit={() => onEditPropietario(propiedad)}
                />
                <div className="-mt-2">
                  {propiedad.id_propietario
                    ? <DetailsList
                        items={[
                          {
                            label: "Nombre",
                            value: data
                              ? `${data[0].nombre} ${data[0].apellido}`
                              : "Cargando..."
                          },
                          {
                            label: "Teléfono",
                            value: data ? data[0].telefono : "Cargando..."
                          },
                          {
                            label: "Email",
                            value: data ? data[0].email : "Cargando..."
                          }
                        ]}
                      />
                    : <NoAssignmentMessage />}
                </div>
              </div>

              {/* Servicios */}
              <div>
                <SectionHeader
                  title="Servicios"
                  icon={<AddCard className="text-purple-600" />}
                  onEdit={() => onEditServicios(propiedad)}
                />
                <p className="text-gray-600">
                  {propiedad.serviciosactivos ? "Activos" : "No activos"}
                </p>
              </div>
            </div>
          </div>
        : <AgregarPropiedad inmueble={propiedad} open={openEditPropiedad} setOpenModal={setOpenEditPropiedad} setInmueble={setActualiza} />}
    </div>
  );
};

const SectionHeader = ({ title, icon, onEdit }) =>
  <div className="flex justify-between items-center mb-6">
    <h3 className="text-xl font-semibold text-gray-800 flex items-center">
      {icon}
      <span className="ml-3">
        {title}
      </span>
    </h3>
    <button
      onClick={onEdit}
      className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-full text-sm shadow-md transition-transform transform hover:scale-105"
    >
      Editar
    </button>
  </div>;

const DetailsList = ({ items }) =>
  <ul className="text-gray-700 space-y-3">
    {items.map((item, index) =>
      <li key={index}>
        <span className="font-semibold">{item.label}:</span>{" "}
        {item.value || "No disponible"}
      </li>
    )}
  </ul>;

const NoAssignmentMessage = () =>
  <p className="text-red-500 font-semibold">No asignado</p>;

export default PropertyCard;
