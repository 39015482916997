import React, { useState, useEffect } from 'react';
import { Button, Modal, Box, Stack, TextField, Avatar, FormControl, MenuItem, Select } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateField } from '@mui/x-date-pickers/DateField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import GavelIcon from '@mui/icons-material/Gavel';
import WhereToVoteIcon from '@mui/icons-material/WhereToVote';
import PercentOutlinedIcon from '@mui/icons-material/PercentOutlined';
import FmdBadOutlinedIcon from '@mui/icons-material/FmdBadOutlined';
import { CARGAR_ACTUALIZAR_CONTRATO, BUSCAR_CONTRATO } from '../../utils/apiUrls';

const InsertarContratoModal = ({ open, setOpenModal, idPropiedad, actualiza, setActualiza, inmueble }) => {
  const [datosContratos, SetDatosContratos] = useState(null);
  const [formulario, setFormulario] = useState({
    inicioContrato: '',
    aniosContrato: '',
    aumentosContrato: '',
    indiceContrato: '',
    tipoContrato: '',
    montoPorcentaje: 0,
    id_propiedad: idPropiedad,
    id: ''
  });

  useEffect(() => {
    const postData = async () => {
      try {
        const response = await fetch(BUSCAR_CONTRATO, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ id_contrato: inmueble.id_contrato }),
        });

        if (response.ok) {
          const responseData = await response.json();
          SetDatosContratos(responseData.data[0]);
          setFormulario({
            inicioContrato: dayjs(responseData.data[0].fecha_inicio) || '',
            aniosContrato: responseData.data[0].duracion_contrato || '',
            aumentosContrato: responseData.data[0].tipo_aumento || '',
            indiceContrato: responseData.data[0].indice_aumento || '',
            tipoContrato: responseData.data[0].tipo_contrato || '',
            montoPorcentaje: responseData.data[0].porcentaje || '',
            id: responseData.data[0].id_contrato || null,
            id_propiedad: inmueble.id,
          });
        } else {
          console.error('Error al enviar el formulario');
        }
      } catch (error) {
        console.error('Error al enviar el formulario:', error);
      }
    };
    postData();
  }, []);

  const handleSave = (value) => {
    setFormulario({ ...formulario, inicioContrato: value });
  };

  const handleSelectChange = (event) => {
    const { name, value } = event.target;
    setFormulario({ ...formulario, [name]: value });
  };

  const handleGuardar = async () => {
    try {
      const response = await fetch(CARGAR_ACTUALIZAR_CONTRATO, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formulario),
      });

      if (response.ok) {
        setActualiza(!actualiza);
        handleClose();
      }
    } catch (error) {
      console.error('Error al realizar la solicitud:', error);
    }
  };

  const handleClose = () => {
    setActualiza(true);
    setOpenModal(!open);
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: "60%",
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4, // Aumentamos el padding para mejor separación
            borderRadius: 2,
          }}
          style={{ maxHeight: '90%', overflow: 'auto' }}
        >
          <div className="min-h-full">
            {/* Título del modal */}
            <div className="text-center bg-blue-600 text-white text-lg p-3 rounded-t-lg">
              CONTRATO
            </div>

            <div className="pt-6 text-center text-black">
              <div className="flex justify-center items-center border-b-2 border-blue-500 pb-2">
                <FmdBadOutlinedIcon sx={{ color: 'blue', marginRight: 1 }} />
                <span className="text-lg">{`${inmueble.direccion} ${inmueble.altura} - Piso: ${inmueble.piso} Unidad: ${inmueble.unidad}`}</span>
              </div>
            </div>

            {/* Formulario */}
            <div className="flex flex-wrap justify-center gap-10 py-8">
              <div className="flex flex-col items-center">
                <Avatar 
                  sx={{ 
                    bgcolor: 'transparent', 
                    border: '3px solid #039be5', 
                    transition: '0.3s', 
                    '&:hover': { backgroundColor: '#e0f7fa' } 
                  }}
                >
                  <CalendarMonthIcon color="success" />
                </Avatar>
                <p className="font-semibold text-blue-500">Inicio Contrato</p>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateField
                    format="DD/MM/YYYY"
                    value={formulario.inicioContrato}
                    onChange={handleSave}
                    fullWidth
                    size="small"
                    sx={{ border: '1px solid #039be5', borderRadius: '4px' }}
                  />
                </LocalizationProvider>
              </div>

              <div className="flex flex-col items-center">
                <Avatar 
                  sx={{ 
                    bgcolor: 'transparent', 
                    border: '3px solid #039be5', 
                    transition: '0.3s', 
                    '&:hover': { backgroundColor: '#e0f7fa' } 
                  }}
                >
                  <WhereToVoteIcon color="success" />
                </Avatar>
                <p className="font-semibold text-blue-500">Años Contrato</p>
                <FormControl fullWidth size="small">
                  <Select
                    value={formulario.aniosContrato}
                    onChange={handleSelectChange}
                    name="aniosContrato"
                    sx={{ border: '1px solid #039be5', borderRadius: '4px' }}
                  >
                    {[1, 2, 3, 4, 5].map((anio) => (
                      <MenuItem key={anio} value={anio}>
                        {anio} Año{anio > 1 && 's'}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              <div className="flex flex-col items-center">
                <Avatar 
                  sx={{ 
                    bgcolor: 'transparent', 
                    border: '3px solid #039be5', 
                    transition: '0.3s', 
                    '&:hover': { backgroundColor: '#e0f7fa' } 
                  }}
                >
                  <TrendingUpIcon color="success" />
                </Avatar>
                <p className="font-semibold text-blue-500">Aumentos</p>
                <FormControl fullWidth size="small">
                  <Select
                    value={formulario.aumentosContrato}
                    onChange={handleSelectChange}
                    name="aumentosContrato"
                    sx={{ border: '1px solid #039be5', borderRadius: '4px' }}
                  >
                    {[1, 2, 3, 6, 12].map((meses) => (
                      <MenuItem key={meses} value={meses}>
                        Cada {meses} Mes{meses > 1 && 'es'}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              </div>
              <div className="flex flex-wrap justify-center gap-10 py-8">

              <div className="flex flex-col items-center">
                <Avatar 
                  sx={{ 
                    bgcolor: 'transparent', 
                    border: '3px solid #039be5', 
                    transition: '0.3s', 
                    '&:hover': { backgroundColor: '#e0f7fa' } 
                  }}
                >
                  <GavelIcon color="success" />
                </Avatar>
                <p className="font-semibold text-blue-500">Índice</p>
                <FormControl fullWidth size="small">
                  <Select
                    value={formulario.indiceContrato}
                    onChange={handleSelectChange}
                    name="indiceContrato"
                    sx={{ border: '1px solid #039be5', borderRadius: '4px' }}
                  >
                    <MenuItem value="IPC">IPC</MenuItem>
                    <MenuItem value="ICL">ICL</MenuItem>
                    <MenuItem value="PORCENTAJE">PORCENTAJE</MenuItem>
                  </Select>
                </FormControl>
              </div>

              {formulario.indiceContrato === 'PORCENTAJE' && (
                <div className="flex flex-col items-center">
                  <Avatar 
                    sx={{ 
                      bgcolor: 'transparent', 
                      border: '3px solid #039be5', 
                      transition: '0.3s', 
                      '&:hover': { backgroundColor: '#e0f7fa' } 
                    }}
                  >
                    <PercentOutlinedIcon color="success" />
                  </Avatar>
                  <p className="font-semibold text-blue-500">Porcentaje</p>
                  <TextField
                    fullWidth
                    size="small"
                    value={formulario.montoPorcentaje}
                    onChange={(e) => setFormulario({ ...formulario, montoPorcentaje: e.target.value })}
                    sx={{ border: '1px solid #039be5', borderRadius: '4px' }}
                  />
                </div>
              )}

              <div className="flex flex-col items-center">
                <Avatar 
                  sx={{ 
                    bgcolor: 'transparent', 
                    border: '3px solid #039be5', 
                    transition: '0.3s', 
                    '&:hover': { backgroundColor: '#e0f7fa' } 
                  }}
                >
                  <MapsHomeWorkIcon color="success" />
                </Avatar>
                <p className="font-semibold text-blue-500">Tipo</p>
                <FormControl fullWidth size="small">
                  <Select
                    value={formulario.tipoContrato}
                    onChange={handleSelectChange}
                    name="tipoContrato"
                    sx={{ border: '1px solid #039be5', borderRadius: '4px' }}
                  >
                    <MenuItem value="Vivienda">Vivienda</MenuItem>
                    <MenuItem value="Comercial">Comercial</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>

            {/* Botones */}
            <div className="flex justify-center mt-6">
              <Stack spacing={3} direction="row">
                <Button 
                  variant="contained" 
                  color="success" 
                  onClick={handleGuardar}
                  sx={{ 
                    '&:hover': { backgroundColor: '#2e7d32', boxShadow: '0px 0px 8px rgba(0,0,0,0.3)' },
                  }}
                >
                  {datosContratos ? 'ACTUALIZAR' : 'GUARDAR'}
                </Button>
                <Button 
                  variant="contained" 
                  color="error" 
                  onClick={handleClose}
                  sx={{ 
                    '&:hover': { backgroundColor: '#d32f2f', boxShadow: '0px 0px 8px rgba(0,0,0,0.3)' },
                  }}
                >
                  CANCELAR
                </Button>
              </Stack>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default InsertarContratoModal;
