// apiUrls.js

const BASE_URL = 'https://www.api.chiapperoinmobiliaria.com.ar'; // Reemplaza esta URL con la URL de tu API
//const BASE_URL = 'http://localhost:7010'; // Reemplaza esta URL con la URL de tu API

export const LOGIN_URL = `${BASE_URL}/login`;
export const INMUEBLES = `${BASE_URL}/inmuebles`;
export const PROPIETARIOS = `${BASE_URL}/all/propietarios`;
export const PROPIETARIOS_RENDIR = `${BASE_URL}/all/propietarios/rendir`;
export const DELETE_PROPIETARIO = `${BASE_URL}/delete/propietario`;
export const TODOSINMUEBLES = `${BASE_URL}/all/inmuebles`;
export const BUSCARINQUILINO = `${BASE_URL}/search/inquilino`;
export const BUSCARPROPIETARIO = `${BASE_URL}/search/propietario`;
export const BUSCARINQUILINOS = `${BASE_URL}/all/inquilinos`;
export const BUSCARPROPIETARIOS = `${BASE_URL}/all/propietarios`;
export const INMUEBLESPORVENCER = `${BASE_URL}/search/inmuebles/porvencer`;
export const INMUEBLES_QUE_AUMENTAN = `${BASE_URL}/search/inmuebles/aumento`;
export const INMUEBLES_GUARDAR_AUMENTO = `${BASE_URL}/update/inmuebles/aumento`;
export const INMUEBLES_HISTORIAL_AUMENTOS = `${BASE_URL}/search/inmuebles/aumento/history`;

export const INMUEBLES_DEUDORES = `${BASE_URL}/search/inmuebles/deudores`;
export const ADD_INMUEBLE = `${BASE_URL}/add/inmuebles`;
export const SEARCH_INMUEBLE_ID = `${BASE_URL}/inmuebles/id`;

export const ADD_INQUILINO = `${BASE_URL}/add/inquilino`;
export const UPDATE_INQUILINO = `${BASE_URL}/update/inquilino`;
export const ADD_SELECT_INQUILINO = `${BASE_URL}/add/select/inquilino`;

export const ADD_SELECT_PROPIETARIO = `${BASE_URL}/add/select/propietario`;
export const ADD_PROPIETARIO = `${BASE_URL}/add/propietario`;

export const UPDATE_PROPIETARIO = `${BASE_URL}/update/propietario`;

export const BUSCARSERVICIOS = `${BASE_URL}/select/notselected/servicios`;
export const BUSCARSERVICIOSSELECCIONADOS = `${BASE_URL}/select/selected/servicios`;
export const BUSCAR_ALQUILERES_RENDIR = `${BASE_URL}/select/alquileres/rendiciones`;
export const BUSCAR_ALQUILERES_RENDIDOS = `${BASE_URL}/select/alquileres/rendidos`;
export const BUSCAR_GASTOS_RENDIR = `${BASE_URL}/select/gastos/rendiciones`;
export const ADD_SERVICIOS_PROPIEDAD = `${BASE_URL}/add/servicios/selected`;
export const ADD_SERVICIO = `${BASE_URL}/add/servicios`;

export const PROPERTY_LIST_URL = `${BASE_URL}/properties`;
export const POST_REGISTER_PAYMENT = `${BASE_URL}/register-Payment`;
export const POST_DELETE_PAYMENT = `${BASE_URL}/delete-Payment`;
export const POST_SEARCH_PAYMENT = `${BASE_URL}/search-Payment`;

export const POST_REGISTER_EXPENSE = `${BASE_URL}/register-Expense`;
export const POST_SEARCH_SERVICE = `${BASE_URL}/search-service`;
export const CARGAR_ACTUALIZAR_CONTRATO = `${BASE_URL}/set/update/contract`;
export const BUSCAR_CONTRATO = `${BASE_URL}/search/contract`;
export const CARGAR_RENDICION = `${BASE_URL}/add/rendicion`;
export const BUSCAR_GASTOS_RENDICION =  `${BASE_URL}/search/gastos/rendicion`;
export const BUSCAR_REINTEGROS_RENDICION =  `${BASE_URL}/search/reintegros/rendicion`;

export const REGISTRAR_GASTO =  `${BASE_URL}/add/gasto`;
export const ELIMINAR_GASTO =  `${BASE_URL}/delete/gasto`;
export const ELIMINAR_RENDICION =  `${BASE_URL}/delete/rendicion`;
export const BUSCAR_CANTIDAD_RENDICIONES =  `${BASE_URL}/search/count/rendiciones`;

export const REGISTRAR_REINTEGRO =  `${BASE_URL}/add/reintegro`;
export const ELIMINAR_REINTEGRO =  `${BASE_URL}/delete/reintegro`;

export const BUSCAR_EDIFICIOS = `${BASE_URL}/search/edificios`;
export const BUSCAR_INMUEBLES_EDIFICIOS = `${BASE_URL}/search/inmuebles/edificios`;
export const BUSCAR_AGREGAR_INMUEBLES_EDIFICIOS = `${BASE_URL}/search/add/inmuebles/edificios`;
export const BUSCAR_EXPENSAS_INDIVIDUAL = `${BASE_URL}/search/individual/expensas`;
export const BUSCAR_MONTO_INDIVIDUAL = `${BASE_URL}/search/expensas/monto`;

export const CARGAR_GASTO_INDIVIDUAL = `${BASE_URL}/add/gasto/expensas`;
export const ELIMINAR_GASTO_INDIVIDUAL = `${BASE_URL}/delete/gasto/expensas`;
export const CARGAR_INMUEBLE = `${BASE_URL}/add/inmueble/edificio`;
export const ELIMINAR_INMUEBLE = `${BASE_URL}/delete/inmueble/expensas`;
export const ELIMINAR_INMUEBLE_INMUEBLES = `${BASE_URL}/delete/inmueble`;
export const CARGAR_EXPENSAS = `${BASE_URL}/add/expensa`;
export const ELIMINAR_EXPENSAS = `${BASE_URL}/delete/expensas`;
export const BUSCAR_EXPENSAS = `${BASE_URL}/search/expensas`;
export const ACTUALIZAR_GASTO = `${BASE_URL}/update/gasto/expensas`;
export const ACTUALIZAR_REINTEGRO = `${BASE_URL}/update/reintegro/expensas`;
export const GENERAR_PDF = `${BASE_URL}/generate-pdf`;
export const BUSCAR_INFO_INMUEBLE = `${BASE_URL}/search/info/inmueble/inquilino`;
export const BUSCAR_INFO_CONTRATO = `${BASE_URL}/search/info/inmueble/contrato`;

export const BUSCAR_SERVICIOS_INMUEBLE = `${BASE_URL}/search/servicios/inmueble`;


// Agrega más URLs según tus necesidades
export const SEARCH_FACT = `${BASE_URL}/search-fact`;
