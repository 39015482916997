import React, { useState, useEffect } from "react";
import { TODOSINMUEBLES, CARGAR_INMUEBLE, ELIMINAR_INMUEBLE_INMUEBLES } from "../../utils/apiUrls";
import SeleccionarInquilino from "./SeleccionarInquilino";
import SeleccionarPropietario from "./SeleccionarPropietario";
import SeleccionarServicios from "./SeleccionarServicios";
import SeleccionarContratos from "./SeleccionarContratos";
import AgregarPropiedad from "./AgregarPropiedad";
import PropertyCard from './ShowMore';

const Index = () => {
  const [rows, setData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [filteredResults, setFilteredResults] = useState([]);
  const [propiedad, setPropiedad] = useState(null); // Propiedad seleccionada para mostrar más información
  const [openSeleccionarInquilino, setOpenSeleccionarInquilino] = useState(false);
  const [openSeleccionarPropietario, setOpenSeleccionarPropietario] = useState(false);
  const [openSeleccionarServicios, setOpenSeleccionarServicios] = useState(false);
  const [openSeleccionarContratos, setOpenSeleccionarContratos] = useState(false);
  const [actualiza, setActualiza] = useState(false);
  const [editPropiedad, setEditPropiedad] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null); // Fila seleccionada
  const [inmueble, setInmueble] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(TODOSINMUEBLES);
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || "Error en la solicitud");
        }
        const data2 = await response.json();
        setData(data2.data);
        setFilteredResults(data2.data);
      } catch (error) {
        console.error("Error de solicitud:", error.message);
      }
    };
    fetchData();
  }, [actualiza]);

  const handleSearchChange = (event) => {
    const searchValue = event.target.value.toLowerCase();
    const filteredResults = rows.filter((row) => row.direccion.toLowerCase().includes(searchValue));
    setSearchValue(event.target.value);
    setFilteredResults(filteredResults);
  };

  const handleEliminar = async (row) => {
    try {
      const response = await fetch(`${ELIMINAR_INMUEBLE_INMUEBLES}/${row.id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error("Error eliminando inmueble");
      }
      setActualiza(!actualiza);
      setOpenDeleteDialog(false);
    } catch (error) {
      console.error("Error eliminando inmueble", error);
    }
  };

  const handleOpenDeleteDialog = (row) => {
    setSelectedRow(row);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setSelectedRow(null);
  };

  const handleSeleccionarPropiedad = (row) => {
    setSelectedRow(row); // Al hacer clic en una propiedad, se marca como seleccionada
    setPropiedad(row); // Se pasa la propiedad seleccionada para mostrar más información
  };

  // Funciones para los botones de editar
  const handleEditPropietario = (propiedad) => {
    setOpenSeleccionarPropietario(true);
  };

  const handleEditInquilino = (propiedad) => {
    setOpenSeleccionarInquilino(true);
  };

  const handleEditContrato = (propiedad) => {
    setOpenSeleccionarContratos(true);
  };

  const handleEditServicios = (propiedad) => {
    setOpenSeleccionarServicios(true);
  };

  return (
    <div className="flex-col w-full -mt-8 h-full  max-h-min">
      <div className="my-2 mx-auto w-full">
        <input
          type="text"
          placeholder="Buscar"
          value={searchValue}
          onChange={handleSearchChange}
          className="w-full p-2 border rounded-lg"
        />
      </div>
      <div className="w-full flex sm:text-sm h-full  max-h-screen " style={{   maxHeight: "calc(100vh - 245px)" , overflowY: "auto" }}>
        <div className="w-5/12" style={{ overflowX: "auto" }}>
          <table className="min-w-full bg-white">
            <thead>
              <tr className="bg-blue-700 text-white">
                <th className="py-2 px-4">DIRECCIÓN</th>
                <th className="py-2 px-4">ELIMINAR</th>
              </tr>
            </thead>
            <tbody>
              {filteredResults.map((row) => (
                <tr
                  key={row.id}
                  className={`border-t cursor-pointer hover:bg-blue-300 hover:text-white hover:font-bold hover:text-base  ${selectedRow === row ? "bg-blue-500 text-white m-1  font-semibold" : ""}`} // Cambia el estilo si la fila está seleccionada
                  onClick={() => handleSeleccionarPropiedad(row)}
                >
                  <td className="py-2 px-4">
                    {row.direccion + " " + row.altura + " - " + row.piso + " - " + row.unidad}
                  </td>
                  <td className="py-2 px-4 text-center">
                    <button onClick={() => handleOpenDeleteDialog(row)} className="text-red-500">
                      🗑️
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {openSeleccionarInquilino && (
            <SeleccionarInquilino
              open={openSeleccionarInquilino}
              setOpenModal={setOpenSeleccionarInquilino}
              inmueble={propiedad}
              actualiza={actualiza}
              setActualiza={setActualiza}
            />
          )}
          {openSeleccionarPropietario && (
            <SeleccionarPropietario
              open={openSeleccionarPropietario}
              setOpenModal={setOpenSeleccionarPropietario}
              inmueble={propiedad}
              actualiza={actualiza}
              setActualiza={setActualiza}
            />
          )}
          {openSeleccionarServicios && (
            <SeleccionarServicios
              open={openSeleccionarServicios}
              setOpenModal={setOpenSeleccionarServicios}
              inmueble={propiedad}
              actualiza={actualiza}
              setActualiza={setActualiza}
            />
          )}
          {openSeleccionarContratos && (
            <SeleccionarContratos
              open={openSeleccionarContratos}
              setOpenModal={setOpenSeleccionarContratos}
              inmueble={propiedad}
              idPropiedad={propiedad.id}
              actualiza={actualiza}
              setActualiza={setActualiza}
            />
          )}
          {editPropiedad && (
            <AgregarPropiedad
              open={editPropiedad}
              setOpenModal={setEditPropiedad}
              setInmueble={setInmueble}
              inmueble={propiedad}
              idPropiedad={propiedad.id}
              actualiza={actualiza}
              setActualiza={setActualiza}
            />
          )}
        </div>

        {/* Componente ShowMore con propiedad seleccionada */}
        <div className="w-7/12 mx-4 -mt-6">
          {propiedad ? 
            <PropertyCard
              propiedad={propiedad}
              onEditPropietario={handleEditPropietario}
              onEditInquilino={handleEditInquilino}
              onEditContrato={handleEditContrato}
              onEditServicios={handleEditServicios}
            />
           : 
           <div className="bg-white shadow-lg rounded-lg overflow-hidden my-6 border border-gray-300 p-10  flex h-full" style={{   maxHeight: "calc(100vh - 270px)"  }}>
              <div className="w-full mx-auto p-10 my-20">
                <p className="p-1 text-lg  font-semibold text-gray-600">Seleccione una propiedad de la tabla</p>
                <p className="p-1 text-lg  font-semibold text-gray-600">Para poder ver la informacion sobre</p>
                <p className="p-1 text-lg  font-semibold text-gray-600">El inmueble que desees.</p>
              </div>
            </div>
          }
        </div>
      </div>

      {/* Modal de eliminación */}
      <div>
        {openDeleteDialog && (
          <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h2 className="text-xl font-bold mb-4">Confirmar Eliminación</h2>
              <p className="mb-4">¿Estás seguro de que deseas eliminar este inmueble?</p>
              <div className="flex justify-end space-x-4">
                <button onClick={handleCloseDeleteDialog} className="bg-gray-300 hover:bg-gray-400 text-black py-2 px-4 rounded">
                  Cancelar
                </button>
                <button onClick={() => handleEliminar(selectedRow)} className="bg-red-500 hover:bg-red-600 text-white py-2 px-4 rounded">
                  Eliminar
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Index;
