import React, { useState } from 'react';
import { Modal } from '@mui/material';
import { Save, Cancel, LocationOn, Home, Apartment, Room, AttachMoney } from '@mui/icons-material';
import Swal from 'sweetalert2';
import { ADD_INMUEBLE } from '../../utils/apiUrls';

const AgregarPropiedad = ({ open, setOpenModal, inmueble, setActualiza, actualiza }) => {
    // Si recibe un inmueble, lo usa para inicializar el formulario; si no, utiliza valores por defecto
    const [formulario, setFormulario] = useState({
        direccion: inmueble?.direccion || '',
        altura: inmueble?.altura || '',
        piso: inmueble?.piso || '',
        dpto: inmueble?.unidad || '',
        provincia: inmueble?.provincia || '',
        localidad: inmueble?.localidad || '',
        dormitorios: inmueble?.dormitorios || '',
        alquiler: inmueble?.alquiler || '',
        precioalquiler: inmueble?.alquiler_precio || '',
        expensas: inmueble?.expensas || '',
        cochera: inmueble?.cochera_tiene || '',
        patio: inmueble?.patio || '',
        ocupado: inmueble?.ocupado || '',
        id:inmueble?.id || null,
    });
    const [seleccionUsuario, setSeleccion] = useState('Inicio')

    const handleChange = (e) => { 
        const { name, value } = e.target;
        setFormulario({ ...formulario, [name]: value });
  
    };


    const handleSave = () => {
        const url =  ADD_INMUEBLE;
        const method = 'POST';

        fetch(url, {
            method: method,
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(formulario),
        })
            .then((response) => response.ok && handleSuccess())
            .catch((error) => console.error('Error:', error));
    };

    const handleSuccess = () => {
        setOpenModal(false);
        setActualiza(!actualiza);
        const message = inmueble ? 'El inmueble se ha actualizado correctamente.' : 'El inmueble se ha cargado correctamente.';
        Swal.fire('Operación exitosa', message, 'success');
    };

    return (
        <Modal open={open} onClose={() => setOpenModal(false)} aria-labelledby="modal-title">
            <div className="absolute top-1/2 left-1/2 w-11/12 md:w-8/12 lg:w-1/2 bg-white shadow-xl rounded-lg p-4 transform -translate-x-1/2 -translate-y-1/2 overflow-auto max-h-[90vh]">
                <div className="text-2xl font-bold text-center py-2 bg-blue-600 text-white rounded-t-md mb-6 shadow-md">
                    {inmueble ? 'EDITAR PROPIEDAD' : 'AGREGAR PROPIEDAD'}
                </div>
                <div className="space-y-6">

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
                        <InputField icon={<LocationOn className='mt-6'/>} label="Dirección" name="direccion" value={formulario.direccion} onChange={handleChange} />
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">

                        <InputField icon={<Home className='mt-8'/>} label="Altura" name="altura" value={formulario.altura} onChange={handleChange} />
                        <InputField icon={<Apartment className='mt-8'/>} label="Piso" name="piso" value={formulario.piso} onChange={handleChange} />
                        <InputField icon={<Room className='mt-8'/>} label="Dpto" name="dpto" value={formulario.dpto} onChange={handleChange} />
                        </div>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
                        <InputField label="Localidad" name="localidad" value={formulario.localidad} onChange={handleChange} />
                        <InputField label="Provincia" name="provincia" value={formulario.provincia} onChange={handleChange} />
                        <SelectField label="Dormitorios" name="dormitorios" value={formulario.dormitorios} options={[1, 2, 3, 4, 5]} onChange={handleChange} />
                        <SelectField label="Alquiler o Venta" name="alquiler" value={ formulario.alquiler } options={[{ label: 'Venta', value: 0 },{ label: 'Alquiler', value: 1 } ]} onChange={handleChange} />
                    </div>
                    {formulario.alquiler === '1' && (
                        <div>
                            <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
                                <InputField icon={<AttachMoney />} label="Precio Alquiler" name="precioalquiler" value={formulario.precioalquiler} onChange={handleChange} />
                                <SelectField label="Expensas" name="expensas" value={formulario.expensas} options={[{ label: 'Sí', value: 1 }, { label: 'No', value: 0 }]} onChange={handleChange} />
                                <SelectField label="Cochera" name="cochera" value={formulario.cochera} options={[{ label: 'Sí', value: 1 }, { label: 'No', value: 0 }]} onChange={handleChange} />
                                <SelectField label="Patio" name="patio" value={formulario.patio} options={[{ label: 'Sí', value: 1 }, { label: 'No', value: 0 }]} onChange={handleChange} />
                                <SelectField 
                                    label="Alquilada?" 
                                    name="ocupado" 
                                    value={formulario.ocupado} 
                                    options={[ { label: 'No', value: '0' },{ label: 'Sí', value: '1' }]} 
                                    onChange={handleChange} 
                                    />
                            </div>
                            {formulario.ocupado == '1' ?  
                                <>
                            <div className='h-[7px] mt-6 bg-blue-500 min-w-full my-2 '> </div>
                            <div className='flex -mb-4'>
                            <div className="mx-auto text-center group  cursor-pointer">
                                <div className="relative"
                                onClick={() =>{setSeleccion('Inicio')}}
                                >
                                    <span className={` ${seleccionUsuario == 'Inicio' ? 'text-gray-500 font-semibold': 'text-gray-700'}`}>Inmueble</span>
                                    <div className={`border-t-2  w-10 group-hover:w-full transition-all duration-300 ease-in-out mx-auto ${seleccionUsuario == 'Inicio' ? 'border-blue-500 min-w-full' : 'border-gray-400' }`}></div>
                                </div>
                                </div>
                                <div className="mx-auto text-center group cursor-pointer"
                                onClick={() =>{setSeleccion('Contrato')}}
                                >
                                    <div className="relative">
                                        <span className={` ${seleccionUsuario == 'Contrato' ? 'text-gray-500 font-semibold': 'text-gray-700'}`}>Contrato</span>
                                        <div className={`border-t-2  w-10 group-hover:w-full transition-all duration-300 ease-in-out mx-auto ${seleccionUsuario == 'Contrato' ? 'border-blue-500 min-w-full' : 'border-gray-400' }`}></div>
                                    </div>
                                </div>
                                <div className="mx-auto text-center group cursor-pointer"
                                onClick={() =>{setSeleccion('Propietario')}}
                                >
                                    <div className="relative">
                                        <span className={` ${seleccionUsuario == 'Propietario' ? 'text-gray-500 font-semibold': 'text-gray-700'}`}>Propietario</span>
                                        <div className={`border-t-2  w-10 group-hover:w-full transition-all duration-300 ease-in-out mx-auto ${seleccionUsuario == 'Propietario' ? 'border-blue-500 min-w-full' : 'border-gray-400' }`}></div>
                                    </div>
                                </div>
                                <div className="mx-auto text-center group cursor-pointer"
                                onClick={() =>{setSeleccion('Inquilino')}}
                                >
                                    <div className="relative">
                                        <span className={` ${seleccionUsuario == 'Inquilino' ? 'text-gray-500 font-semibold': 'text-gray-700'}`}>Inquilino</span>
                                        <div className={`border-t-2  w-10 group-hover:w-full transition-all duration-300 ease-in-out mx-auto ${seleccionUsuario == 'Inquilino' ? 'border-blue-500 min-w-full' : 'border-gray-400' }`}></div>
                                    </div>
                                </div>
                            </div>
                            </>
                            : null }


                        </div>
                    )}
                </div>
                <div className="mt-8 flex justify-center space-x-4">
                    <button className="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-6 rounded-lg flex items-center" onClick={handleSave}>
                        <Save className="mr-2" /> {inmueble ? 'Actualizar' : 'Guardar'}
                    </button>
                    <button className="bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-6 rounded-lg flex items-center" onClick={() => setOpenModal(false)}>
                        <Cancel className="mr-2" /> Cancelar
                    </button>
                </div>
            </div>
        </Modal>
    );
};

const InputField = ({ icon, label, ...props }) => (
    <div className="flex items-center space-x-2">
        {icon && <span className="text-gray-600">{icon}</span>}
        <div className="flex-1">
            <label className="block font-semibold text-gray-700 mb-1">{label}</label>
            <input type="text" className="w-full border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500" {...props} />
        </div>
    </div>
);

const SelectField = ({ label, options, ...props }) => (
    <div>
        <label className="block font-semibold text-gray-700 mb-1">{label}</label>
        <select className="w-full border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500" {...props}>
            {options.map((opt) => (
                <option key={opt.value || opt} value={opt.value || opt}>
                    {opt.label || opt}
                </option>
            ))}
        </select>
    </div>
);

export default AgregarPropiedad;
